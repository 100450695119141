@keyframes pulse {
    from {
        transform: scale(1);
        opacity: .5;
    }
    to {
        transform: scale(1.4);
        opacity: 0;
    }
}

@keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }