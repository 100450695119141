    $xsh1: 2rem;
    $xsh2: 1.4rem;
    $xsa: 1rem;
    $xshead: 1rem;
    $xstitle: 1.1rem;
    $xstext: 0.9rem;
    
    $sslogop: 2rem;
    $sslogo: 1.4rem;
    $ssh1: 2rem;
    $ssh2: 1.4rem;
    $ssa: 1rem;
    $sshead: 1.4rem;
    $sstitle: 1.1rem;
    $sstext: 0.9rem;
    
    $mslogop: 2.4rem;
    $mslogo: 1.8rem;
    $msh1: 2.8rem;
    $msh2: 2.4rem;
    $msa: 1.2rem;
    $mshead: 1.7rem;
    $mstitle: 1.3rem;
    $mstext: 1.1rem;
    
    $lslogop: 2.8rem;
    $lslogo: 2.2rem;
    $lsh1: 3.4rem;
    $lsh2: 2.8rem;
    $lsa: 1.6rem;
    $lshead: 2.2rem;
    $lstitle: 1.8rem;
    $lstext: 1.4rem;
 