@keyframes pulse {
  from {
    transform: scale(1);
    opacity: 0.5;
  }
  to {
    transform: scale(1.4);
    opacity: 0;
  }
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0em;
  padding: 0em;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  scroll-padding-top: 59px;
}

body {
  font-family: "Lato", sans-serif;
  color: #252525;
  scroll-behavior: smooth;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.head {
  text-align: center;
  font-weight: 500;
  margin-top: 1em;
  padding: 0.4em 0em 0.4em 0em;
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media (min-width: 0px) {
  .head {
    font-size: 1.4rem;
  }
}
@media (min-width: 768px) {
  .head {
    font-size: 1.7rem;
  }
}
@media (min-width: 992px) {
  .head {
    font-size: 2.2rem;
  }
}

hr {
  margin: 0 auto;
}
@media (min-width: 0px) {
  hr {
    width: 20%;
  }
}
@media (min-width: 992px) {
  hr {
    width: 10%;
  }
}

.cookies {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  padding: 2em;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10000;
  border: 1px white;
  border-radius: 5px;
}
@media (min-width: 0px) {
  .cookies {
    width: 60%;
  }
}
@media (min-width: 768px) {
  .cookies {
    width: 35%;
  }
}
@media (min-width: 992px) {
  .cookies {
    width: 30%;
  }
}
.cookies__text {
  text-align: center;
  padding-bottom: 1em;
  color: white;
}
.cookies__btn {
  height: 2em;
  width: 6em;
  border: 1px;
  border-radius: 5px;
  background-color: rgb(97, 179, 144);
  color: white;
  cursor: pointer;
}

.shadow {
  background-color: rgb(1, 53, 44);
}

.hide {
  display: none;
}

.active {
  background-color: rgb(1, 53, 44);
  color: white;
}

.nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  height: 6%;
  width: 100%;
  z-index: 12222;
}
@media (min-width: 992px) {
  .nav {
    height: 9%;
  }
}
.nav__logo {
  position: absolute;
  letter-spacing: 1px;
  text-decoration: none;
  color: rgb(253, 253, 253);
  left: 8%;
}
@media (min-width: 0px) {
  .nav__logo {
    font-size: 1.4rem;
  }
}
@media (min-width: 768px) {
  .nav__logo {
    font-size: 1.8rem;
  }
}
@media (min-width: 992px) {
  .nav__logo {
    font-size: 2.2rem;
  }
}
.nav__logo--p {
  display: inline-block;
  font-family: "Rochester", cursive;
  letter-spacing: 0px;
  text-decoration: none;
  color: rgb(253, 253, 253);
}
@media (min-width: 0px) {
  .nav__logo--p {
    font-size: 2rem;
  }
}
@media (min-width: 768px) {
  .nav__logo--p {
    font-size: 2.4rem;
  }
}
@media (min-width: 992px) {
  .nav__logo--p {
    font-size: 2.8rem;
  }
}
.nav__logo--a, .nav__logo--a:hover {
  text-decoration: none;
  color: rgb(253, 253, 253);
}
.nav__item {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(1, 53, 44);
  text-align: center;
  border-radius: 10px;
  transform: translateX(100%);
  transition: transform 0.5s;
}
@media (min-width: 0px) {
  .nav__item {
    height: 50vh;
    width: 50%;
  }
}
@media (min-width: 768px) {
  .nav__item {
    height: 50vh;
    width: 40%;
  }
}
@media (min-width: 992px) {
  .nav__item {
    height: 70vh;
    width: 25%;
  }
}
.nav__item .nav__btn--close {
  position: absolute;
  top: 4%;
  color: rgb(253, 253, 253);
  cursor: pointer;
}
@media (min-width: 0px) {
  .nav__item .nav__btn--close {
    right: 27%;
    font-size: 2rem;
  }
}
@media (min-width: 768px) {
  .nav__item .nav__btn--close {
    right: 25%;
    font-size: 2.5rem;
  }
}
@media (min-width: 992px) {
  .nav__item .nav__btn--close {
    right: 35%;
    font-size: 2.5rem;
  }
}
.nav__item .nav__btn--close:hover {
  transform: rotate(360deg);
  transition: transform 0.4s;
}
.nav__item--a {
  position: relative;
  margin: 1em;
  padding-left: 1em;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1.5px;
  color: rgb(253, 253, 253);
}
@media (min-width: 0px) {
  .nav__item--a {
    font-size: 0.9rem;
  }
}
@media (min-width: 768px) {
  .nav__item--a {
    font-size: 1.1rem;
  }
}
@media (min-width: 992px) {
  .nav__item--a {
    font-size: 1.4rem;
  }
}
.nav__item--a::before {
  position: absolute;
  top: 0;
  left: 0px;
  right: 15px;
  width: 3px;
  height: 100%;
  transform: scaleY(0);
  background-color: rgb(253, 253, 253);
  transition: transform 0.3s;
  content: "";
}
.nav__item--a:hover::before {
  transform: scaleY(1);
}
.nav__btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 8%;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: rgb(253, 253, 253);
  cursor: pointer;
}
.nav__btn img {
  filter: invert(90%);
}
.nav__btn:hover {
  background-color: rgb(1, 53, 44);
  transition: background-color 0.4s;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  z-index: -10;
  perspective: 2000px;
}
@media (min-width: 0px) {
  .header {
    background-image: url(/dist/img/heroimg_640.webp);
  }
}
@media (min-width: 768px) {
  .header {
    background-image: url(/dist/img/heroimg_1280.webp);
  }
}
.header__shadow {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 0;
}
.header__text {
  position: relative;
  text-align: center;
}
.header__text--h1 {
  font-weight: bold;
  padding: 0.5em 0em;
  color: #252525;
  color: rgb(253, 253, 253);
  animation: fade 3s ease;
}
@media (min-width: 0px) {
  .header__text--h1 {
    font-size: 2rem;
  }
}
@media (min-width: 768px) {
  .header__text--h1 {
    font-size: 2.8rem;
  }
}
@media (min-width: 992px) {
  .header__text--h1 {
    font-size: 3.4rem;
  }
}
.header__text--h2 {
  margin-bottom: 2em;
  color: rgb(1, 53, 44);
  animation: fade 3s ease-in-out;
}
@media (min-width: 0px) {
  .header__text--h2 {
    font-size: 1.4rem;
  }
}
@media (min-width: 768px) {
  .header__text--h2 {
    font-size: 2.4rem;
  }
}
@media (min-width: 992px) {
  .header__text--h2 {
    font-size: 2.8rem;
  }
}
.header__text--a {
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 1px;
  padding: 0.4em 0.8em;
  text-transform: uppercase;
  border: 2px solid;
  border-radius: 10px;
  color: white;
  animation: fade 5s ease-in-out;
}
@media (min-width: 0px) {
  .header__text--a {
    font-size: 1rem;
  }
}
@media (min-width: 768px) {
  .header__text--a {
    font-size: 1.2rem;
  }
}
@media (min-width: 992px) {
  .header__text--a {
    font-size: 1.6rem;
  }
}
.header__text--a:hover {
  background-color: rgb(1, 53, 44);
  transition: background-color 0.4s;
}
.header__icon {
  position: absolute;
  bottom: 25%;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  animation: fade 5s ease-in-out;
}
@media (min-width: 992px) {
  .header__icon {
    bottom: 15%;
  }
}
@media (min-width: 992px) {
  .header__icon {
    bottom: 15%;
  }
}
.header__icon::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid rgb(253, 253, 253);
  border-radius: 50%;
  opacity: 0;
  animation: pulse 1s infinite;
}
.header__icon img {
  filter: invert(90%);
}
@media (min-width: 0px) {
  .header__icon img {
    height: 3.5rem;
  }
}
@media (min-width: 768px) {
  .header__icon img {
    height: 4rem;
  }
}
.header__icon:hover {
  background-color: rgb(1, 53, 44);
  transition: background-color 0.4s;
}

.service__cards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 1.5em;
}
.service__cards .service__card {
  position: relative;
  padding: 1em;
  border-radius: 10px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 11;
}
@media (min-width: 0px) {
  .service__cards .service__card {
    height: 210px;
    width: 240px;
    margin: 0.7em;
  }
}
@media (min-width: 768px) {
  .service__cards .service__card {
    height: 240px;
    width: 290px;
    margin: 1em;
  }
}
@media (min-width: 992px) {
  .service__cards .service__card {
    height: 290px;
    width: 320px;
    margin: 1.5em;
  }
}
.service__cards .service__card--shadow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.service__cards .service__card--img {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgb(1, 53, 44);
  width: 100%;
  height: 50%;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 0;
}
.service__cards .service__card--img-one {
  background-image: url(/dist/img/projektujemy_640.webp);
}
.service__cards .service__card--img-two {
  background-image: url(/dist/img/tworzymy_320.webp);
}
.service__cards .service__card--img-three {
  background-image: url(/dist/img/utrzymujemy_320.webp);
}
.service__cards .service__card--img-four {
  background-image: url(/dist/img/doradzamy_320.webp);
}
.service__cards .service__card--img-five {
  background-image: url(/dist/img/szkolimy_320.webp);
}
.service__cards .service__card--img-six {
  background-image: url(/dist/img/wiata_320.webp);
}
.service__cards .service__card--text {
  position: absolute;
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  right: 0;
  width: 100%;
  height: 50%;
  bottom: 0;
  z-index: 3;
  background-size: cover;
  background-position: center;
  backdrop-filter: blur(10px);
  border-radius: 5px;
}
@media (min-width: 0px) {
  .service__cards .service__card--text {
    font-size: 0.9rem;
  }
}
@media (min-width: 768px) {
  .service__cards .service__card--text {
    font-size: 1.1rem;
  }
}
@media (min-width: 992px) {
  .service__cards .service__card--text {
    font-size: 1.4rem;
  }
}
.service__cards .service__card--text-title {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -2em;
  width: 100%;
  height: 2em;
  text-align: center;
  text-transform: uppercase;
  color: rgb(253, 253, 253);
  background-size: cover;
  background-position: center;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 22;
}
@media (min-width: 0px) {
  .service__cards .service__card--text-title {
    font-size: 1.1rem;
  }
}
@media (min-width: 768px) {
  .service__cards .service__card--text-title {
    font-size: 1.3rem;
  }
}
@media (min-width: 992px) {
  .service__cards .service__card--text-title {
    font-size: 1.8rem;
  }
}
.service__cards .service__card--text-p {
  padding: 1.5em;
}
.service__cards .service__card--text-list {
  padding: 1.5em;
}
.service__cards .service__card--text-list li {
  text-align: left;
  list-style-type: none;
}
.service__cards .service__card .service__card--text-list-check {
  height: 1.5rem;
  padding-right: 0.4em;
  color: rgb(1, 53, 44);
}

.call {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  text-align: center;
  background: rgb(1, 53, 44);
}
@media (min-width: 0px) {
  .call {
    height: 100px;
    font-size: 0.9rem;
  }
}
@media (min-width: 768px) {
  .call {
    height: 120px;
    font-size: 1.1rem;
  }
}
@media (min-width: 992px) {
  .call {
    height: 150px;
    font-size: 1.4rem;
  }
}
.call__box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 90%;
  margin: 20px auto;
  border: 1px solid white;
  border-radius: 10px;
}
.call__box--text {
  text-transform: uppercase;
  color: white;
}
@media (min-width: 0px) {
  .call__box--text {
    font-size: 1.1rem;
  }
}
@media (min-width: 768px) {
  .call__box--text {
    font-size: 1.3rem;
  }
}
@media (min-width: 992px) {
  .call__box--text {
    font-size: 1.8rem;
  }
}

.cooperation {
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  flex-wrap: wrap;
}
.cooperation__card {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2em;
}
@media (min-width: 0px) {
  .cooperation__card {
    height: 130px;
    width: 130px;
    font-size: 1.1rem;
  }
}
@media (min-width: 768px) {
  .cooperation__card {
    height: 170px;
    width: 170px;
    font-size: 1.3rem;
  }
}
@media (min-width: 992px) {
  .cooperation__card {
    height: 250px;
    width: 250px;
    font-size: 1.8rem;
  }
}
.cooperation__card--circle {
  align-content: center;
  margin: 0 auto;
  margin-bottom: 1em;
  background: rgb(1, 53, 44);
  border: 1px;
  border-radius: 50%;
}
@media (min-width: 0px) {
  .cooperation__card--circle {
    height: 40px;
    width: 40px;
    font-size: 1.1rem;
  }
}
@media (min-width: 768px) {
  .cooperation__card--circle {
    height: 50px;
    width: 50px;
    font-size: 1.3rem;
  }
}
@media (min-width: 992px) {
  .cooperation__card--circle {
    height: 60px;
    width: 60px;
    font-size: 1.8rem;
  }
}
.cooperation__card--circle .cooperation__card--image {
  filter: invert(90%);
}
@media (min-width: 0px) {
  .cooperation__card--circle .cooperation__card--image {
    margin-top: 7px;
  }
}
@media (min-width: 768px) {
  .cooperation__card--circle .cooperation__card--image {
    margin-top: 12px;
  }
}
@media (min-width: 992px) {
  .cooperation__card--circle .cooperation__card--image {
    margin-top: 17px;
  }
}
@media (min-width: 0px) {
  .cooperation__card--title {
    font-size: 1.1rem;
  }
}
@media (min-width: 768px) {
  .cooperation__card--title {
    font-size: 1.3rem;
  }
}
@media (min-width: 992px) {
  .cooperation__card--title {
    font-size: 1.8rem;
  }
}
.cooperation__card--text {
  margin-top: 1em;
}
@media (min-width: 0px) {
  .cooperation__card--text {
    font-size: 0.9rem;
  }
}
@media (min-width: 768px) {
  .cooperation__card--text {
    font-size: 1.1rem;
  }
}
@media (min-width: 992px) {
  .cooperation__card--text {
    font-size: 1.4rem;
  }
}
.cooperation__card:hover {
  transform: scale(1.1);
}

.recomendation {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-attachment: scroll;
}
@media (min-width: 0px) {
  .recomendation {
    background-image: url(/dist/img/shaking-hands_640.webp);
  }
}
@media (min-width: 768px) {
  .recomendation {
    background-image: url(/dist/img/shaking-hands_1280.webp);
  }
}
@media (min-width: 992px) {
  .recomendation {
    background-image: url(/dist/img/shaking-hands_1900.webp);
  }
}
.recomendation__shadow {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 0;
}
.recomendation__head {
  position: relative;
  text-align: center;
  font-weight: 500;
  margin-top: 1em;
  padding: 0.4em 0em 0.4em 0em;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgb(253, 253, 253);
  z-index: 2;
}
@media (min-width: 0px) {
  .recomendation__head {
    font-size: 1.4rem;
  }
}
@media (min-width: 768px) {
  .recomendation__head {
    font-size: 1.7rem;
  }
}
@media (min-width: 992px) {
  .recomendation__head {
    font-size: 2.2rem;
  }
}
.recomendation__line {
  position: relative;
  color: rgb(253, 253, 253);
  z-index: 3;
}
.recomendation__box {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 1em 0.5em;
}
.recomendation__box--item {
  position: relative;
  margin: 1.5em 0.5em 2em 0.5em;
}
@media (min-width: 0px) {
  .recomendation__box--item {
    height: 150px;
    width: 200px;
  }
}
@media (min-width: 768px) {
  .recomendation__box--item {
    height: 200px;
    width: 150px;
  }
}
@media (min-width: 992px) {
  .recomendation__box--item {
    height: 300px;
    width: 250px;
  }
}
.recomendation__box--item-img {
  position: relative;
  object-fit: cover;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.recomendation__box--item-img:hover {
  transform: scale(1.02);
}
.recomendation__modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 10%;
  right: 10%;
  top: 10%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 122222;
  visibility: hidden;
  transition: opacity 0.4s ease-in-out;
  cursor: pointer;
}
@media (min-width: 0px) {
  .recomendation__modal {
    width: 80%;
    height: 70%;
  }
}
@media (min-width: 768px) {
  .recomendation__modal {
    width: 50%;
    height: 50%;
  }
}
@media (min-width: 992px) {
  .recomendation__modal {
    width: 28%;
    height: 90%;
  }
}

.modal__img {
  position: relative;
  height: 95%;
  width: 95%;
  transition: 0.4s ease-in-out;
  object-fit: fill;
}

.aboutus {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 0px) {
  .aboutus {
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  .aboutus {
    flex-direction: row;
  }
}
.aboutus__boxes {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  margin-top: 1em;
  margin-bottom: 1px;
  width: 100%;
  background-size: cover;
  background-position: center;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.1);
}
@media (min-width: 0px) {
  .aboutus__boxes {
    justify-content: space-evenly;
  }
}
@media (min-width: 768px) {
  .aboutus__boxes {
    order: 1;
    height: 100%;
    width: 40%;
    border-radius: 10px;
  }
}
.aboutus__boxes__box {
  text-align: center;
  padding: 1em;
}
@media (min-width: 0px) {
  .aboutus__boxes__box {
    height: 80px;
    width: 100px;
  }
}
@media (min-width: 768px) {
  .aboutus__boxes__box {
    height: 70px;
    width: 170px;
  }
}
@media (min-width: 992px) {
  .aboutus__boxes__box {
    height: 90px;
    width: 200px;
  }
}
.aboutus__boxes__box--number {
  padding: 0.5em;
  font-weight: bold;
  color: rgb(1, 53, 44);
}
@media (min-width: 0px) {
  .aboutus__boxes__box--number {
    font-size: 1.1rem;
  }
}
@media (min-width: 768px) {
  .aboutus__boxes__box--number {
    font-size: 1.3rem;
  }
}
@media (min-width: 992px) {
  .aboutus__boxes__box--number {
    font-size: 1.8rem;
  }
}
@media (min-width: 0px) {
  .aboutus__boxes__box--text {
    font-size: 0.9rem;
  }
}
@media (min-width: 768px) {
  .aboutus__boxes__box--text {
    font-size: 1.1rem;
  }
}
@media (min-width: 992px) {
  .aboutus__boxes__box--text {
    font-size: 1.4rem;
  }
}
.aboutus__text {
  position: relative;
  text-align: left;
}
@media (min-width: 0px) {
  .aboutus__text {
    margin: 1em 2em;
  }
}
@media (min-width: 768px) {
  .aboutus__text {
    margin: 0em 1em 0em 2em;
  }
}
.aboutus__text--p {
  padding: 1em 0em 0em 0em;
}
@media (min-width: 0px) {
  .aboutus__text--p {
    font-size: 0.9rem;
  }
}
@media (min-width: 768px) {
  .aboutus__text--p {
    font-size: 1.1rem;
  }
}
@media (min-width: 992px) {
  .aboutus__text--p {
    font-size: 1.4rem;
  }
}
.aboutus__text ol {
  margin-left: 1em;
}
@media (min-width: 0px) {
  .aboutus__text ol {
    font-size: 0.9rem;
  }
}
@media (min-width: 768px) {
  .aboutus__text ol {
    font-size: 1.1rem;
  }
}
@media (min-width: 992px) {
  .aboutus__text ol {
    font-size: 1.4rem;
  }
}

.contact {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.contact__box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 0px) {
  .contact__box {
    margin: 1em;
  }
}
@media (min-width: 768px) {
  .contact__box {
    margin: 3em;
  }
}
.contact__box--logo {
  position: relative;
}
@media (min-width: 0px) {
  .contact__box--logo {
    width: 80px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .contact__box--logo {
    width: 100px;
    height: 40px;
  }
}
@media (min-width: 992px) {
  .contact__box--logo {
    width: 150px;
    height: 50px;
  }
}
.contact__box--title {
  padding: 1em;
  color: rgb(1, 53, 44);
}
@media (min-width: 0px) {
  .contact__box--title {
    font-size: 1.1rem;
  }
}
@media (min-width: 768px) {
  .contact__box--title {
    font-size: 1.3rem;
  }
}
@media (min-width: 992px) {
  .contact__box--title {
    font-size: 1.8rem;
  }
}
.contact__box--text {
  padding-bottom: 0.5em;
}
@media (min-width: 0px) {
  .contact__box--text {
    font-size: 0.9rem;
  }
}
@media (min-width: 768px) {
  .contact__box--text {
    font-size: 1.1rem;
  }
}
@media (min-width: 992px) {
  .contact__box--text {
    font-size: 1.4rem;
  }
}
@media (min-width: 0px) {
  .contact__box--text .contact__box--icon {
    height: 0.9rem;
  }
}
@media (min-width: 768px) {
  .contact__box--text .contact__box--icon {
    height: 1.1rem;
  }
}
@media (min-width: 992px) {
  .contact__box--text .contact__box--icon {
    height: 1.4rem;
  }
}
.contact__box--a {
  display: block;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: #252525;
}
@media (min-width: 0px) {
  .contact__box--a {
    font-size: 0.9rem;
  }
}
@media (min-width: 768px) {
  .contact__box--a {
    font-size: 1.1rem;
  }
}
@media (min-width: 992px) {
  .contact__box--a {
    font-size: 1.4rem;
  }
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.2);
}
@media (min-width: 0px) {
  footer {
    height: 10%;
  }
}
@media (min-width: 768px) {
  footer {
    height: 15%;
  }
}
@media (min-width: 992px) {
  footer {
    height: 20%;
  }
}

.navShow {
  transform: translateX(0%);
}

.visible {
  visibility: visible;
}