@use './colors' as c;
@use './fonts' as f;
@use './animations' as *;
//@use './mixins' as m;
@use './breakpoints' as *;



*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0em;
  padding: 0em;
}


//MAIN

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  scroll-padding-top: 59px;
}

body {
  font-family: 'Lato', sans-serif;
  color: c.$black;
  scroll-behavior: smooth;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}



//ALL SECTION HEAD

.head {
  text-align: center;
  font-weight: 500;
  margin-top: 1em;
  padding: .4em 0em .4em 0em;
  text-transform: uppercase;
  letter-spacing: 1px;
  @include sm {
    font-size: f.$sshead;
  }
  @include md {
    font-size: f.$mshead;
  }
  @include lg {
    font-size: f.$lshead;
  }
}

hr {
  @include sm {
    width: 20%;
  }
  @include lg {
    width: 10%;
  }
  margin: 0 auto;
}

.cookies {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  
  @include sm {
    width: 60%;
  }
  @include md {
    width: 35%;
  }
  @include lg {
    width: 30%;
  }
  padding: 2em;
  background-color: rgba(0, 0, 0, .3);
  z-index: 10000;
  border: 1px  white;
  border-radius: 5px; 
  
  &__text {
    text-align: center;
    padding-bottom: 1em;
    color: white;
  }

  &__btn {
    height: 2em;
    width: 6em;
    border: 1px;
    border-radius: 5px;
    background-color: c.$greenlight;
    color: white;
    cursor: pointer;
  }
}

.shadow {
  background-color: c.$greendark;
}

.hide {
  display: none;
}

.active {
  background-color: c.$greendark;
  color: white;
}

//-----------------------nav-------------------------
.nav {
  position: relative;
  display: flex;
  align-items:center;
  justify-content:space-around;
  position: fixed;
  height: 6%;
  @include lg {
    height: 9%;
  }
  width: 100%;
  // background-size: cover;
  // background-position: center;
  // backdrop-filter: blur(10px);
  z-index: 12222;

  &__logo {
    position: absolute;
    @include sm {
      font-size: f.$sslogo;
    }
    @include md {
      font-size: f.$mslogo;
    }
    @include lg {
      font-size: f.$lslogo;
    }
    letter-spacing: 1px;
    text-decoration: none;
    color: c.$white;
    left: 8%;

    &--p {
      display: inline-block;
      font-family: 'Rochester', cursive;
      @include sm {
        font-size: f.$sslogop;
      }
      @include md {
        font-size: f.$mslogop;
      }
      @include lg {
        font-size: f.$lslogop;
      }
      letter-spacing: 0px;
      text-decoration: none;
      color: c.$white;

    }
    &--a, &--a:hover {
      text-decoration: none;
      color: c.$white;
    }
  }
  &__item{
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    align-items:center;
    justify-content:center;
    flex-direction: column;

    @include sm {
      height: 50vh;
      width: 50%;
    }
    @include md {
      height: 50vh;
      width: 40%;
    }
    @include lg {
      height: 70vh;
      width: 25%;
    }
    background-color: c.$greendark;
    text-align: center;
    border-radius: 10px;
    transform: translateX(100%);
    transition: transform .5s;

   .nav__btn--close {
    position: absolute;
    top: 4%;
    @include sm {
      right: 27%;
      font-size: 2rem;
    }
    @include md {
      right: 25%;
      font-size: 2.5rem;
    }
    @include lg {
      right: 35%;
      font-size: 2.5rem;
    }

    color: c.$white;
    cursor: pointer;
   }
   .nav__btn--close:hover {
   transform: rotate(360deg);
   transition: transform .4s;
   }

    &--a {
      position: relative;
      @include sm {
        font-size: f.$sstext;
      }
      @include md {
        font-size: f.$mstext;
      }
      @include lg {
        font-size: f.$lstext;
      }
      margin: 1em;
      padding-left: 1em;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1.5px;
      color: c.$white;

      &::before {
        position: absolute;
        top: 0;
        left: 0px;
        right: 15px;
        width: 3px;
        height: 100%;
        transform: scaleY(0);
        background-color: c.$white;
        transition: transform .3s;
        content: '';
      }

      &:hover::before {
        transform: scaleY(1);
      }
    }
  }
  
  &__btn {
   position: absolute;
   display: flex;
   align-items: center;
   justify-content: center;
   right: 8%;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: c.$white;
    cursor: pointer;

    // &--text {
    //   font-size: 1.5rem;
    //   margin-right: 1em;
    //   color: $white;
    // }

    img {
      filter: invert(90%);
    }
  }
  &__btn:hover {
    background-color: c.$greendark;
    transition: background-color .4s;
  }
}

///--------------------------------------------- header -----------------------------------------

.header {
  display: flex;
  align-items:center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  @include sm {
    background-image: url(/dist/img/heroimg_640.webp);
  }
  @include md {
    background-image: url(/dist/img/heroimg_1280.webp);
  }
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  z-index: -10;
  perspective: 2000px;

  &__shadow {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color:rgba(0, 0, 0, .2) ;
    z-index: 0;
  }

  &__text {
    position: relative;
    text-align: center;
    &--h1 {
      @include sm {
        font-size: f.$ssh1;
      }
      @include md {
        font-size: f.$msh1;
      }
      @include lg {
        font-size: f.$lsh1;
      }
      font-weight: bold;
      padding: .5em 0em;
      color: c.$black;
      color: c.$white;
      animation: fade 3s ease;
    }



    &--h2 {
      @include sm {
        font-size: f.$ssh2;
      }
      @include md {
        font-size: f.$msh2;
      }
      @include lg {
        font-size: f.$lsh2;
      }
      margin-bottom: 2em;
      color: c.$greendark;
      animation: fade 3s ease-in-out;
    }

    &--a {
      text-align: center;
      @include sm {
        font-size: f.$ssa;
      }
      @include md {
        font-size: f.$msa;
      }
      @include lg {
        font-size: f.$lsa;
      }
      font-weight: bold;
      text-decoration: none;
      letter-spacing: 1px;
      padding: .4em .8em;
      text-transform: uppercase;
      border: 2px solid;
      border-radius: 10px;
      color: white;
      animation: fade 5s ease-in-out;
    }

    &--a:hover {
      background-color: c.$greendark;
      transition: background-color .4s;
    }
  }
  &__icon {
    position: absolute;
    bottom: 25%;
    height: 40px;
    width: 40px;
    @include lg {
      bottom: 15%;
    }
    @include lg {
      bottom: 15%;
    }
    border-radius: 50%;
    cursor: pointer;
    animation: fade 5s ease-in-out;
    

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;
      border: 1px solid c.$white;
      border-radius: 50%;
      opacity: 0;
      animation: pulse 1s infinite;
    }
    img {
      @include sm {
        height:  3.5rem;
      }
      @include md {
        height:  4rem;
      }
      filter: invert(90%);
    }
  }

  &__icon:hover {
    background-color: c.$greendark;
    transition: background-color .4s;
  }
}

///--------------------------------------------- service -----------------------------------------

.service {
  &__cards {
    display: flex;
    align-items:center;
    justify-content: center;
    flex-wrap:wrap;
    margin: 1.5em;
    .service__card {
      position: relative;
      @include sm {
        height: 210px;
        width: 240px;
        margin: .7em;
      }
      @include md {
        height: 240px;
        width: 290px;
        margin: 1em;
      }
      @include lg {
        height: 290px;
        width: 320px;
        margin: 1.5em;
      }
      padding: 1em;
      border-radius: 10px;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      backdrop-filter: blur(10px);
      background-color: rgba(0, 0, 0, .1);
      z-index: 11;
      //border-bottom: 2px solid c.$greendark;
      
      &--shadow {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 50%;
        background-color: rgba(0, 0, 0, .2);
        z-index: 1;
      }
      
      &--img {
        position: absolute;
        left: 0;
        top: 0;
        background-color: c.$greendark;
        width: 100%;
        height: 50%;
        background-position: center;
        background-size: cover;
        overflow: hidden;
        background-color: rgba(0, 0, 0, .7);
        z-index: 0;
  
        &-one{
          background-image: url(/dist/img/projektujemy_640.webp);
        }
        &-two{
          background-image: url(/dist/img/tworzymy_320.webp);
        }
        &-three{
          background-image: url(/dist/img/utrzymujemy_320.webp);
        }
        &-four{
          background-image: url(/dist/img/doradzamy_320.webp);
        }
        &-five{
          background-image: url(/dist/img/szkolimy_320.webp);
        }
        &-six{
          background-image: url(/dist/img/wiata_320.webp);
        }
     
      }
      &--text {
        position: absolute;
        display: flex;
        align-items:left;
        justify-content: center;
        flex-direction: column;
        right: 0;
        width: 100%;
        height: 50%;
        bottom: 0;
        @include sm {
          font-size: f.$sstext;
        }
        @include md {
          font-size: f.$mstext;
        }
        @include lg {
          font-size: f.$lstext;
        }
        z-index: 3;
        background-size: cover;
        background-position: center;
        backdrop-filter: blur(10px);
        border-radius: 5px;
        
        &-title {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          top: -2em;
          width: 100%;
          height: 2em;
          text-align: center;
          @include sm {
            font-size: f.$sstitle;
          }
          @include md {
            font-size: f.$mstitle;
          }
          @include lg {
            font-size: f.$lstitle;
          }
          text-transform: uppercase;
          color: c.$white;
          background-size: cover;
          background-position: center;
          backdrop-filter: blur(10px);
          background-color: rgba(0, 0, 0, .5);
          z-index: 22;
        }
        &-p {
          padding: 1.5em;
        }
        &-list {
          padding: 1.5em;

          li {
            text-align: left;
            list-style-type: none;
          }
        }
      }
      .service__card--text-list-check {
        height: 1.5rem;
        padding-right: .4em;
        color: c.$greendark;
        //filter: invert(90%);
      }
    }
  }
}

///--------------------------------------------- call div -----------------------------------------
.call {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @include sm {
    height: 100px;
    font-size: f.$sstext;
  }
  @include md {
    height: 120px;
    font-size: f.$mstext;
  }
  @include lg {
    height: 150px;
    font-size: f.$lstext;
  }
  flex-direction: column;
  text-align: center;
  background: c.$greendark;

  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 90%;
    margin: 20px auto;
    border: 1px solid white;
    border-radius: 10px;

    &--text {
      @include sm {
        font-size: f.$sstitle;
      }
      @include md {
        font-size: f.$mstitle;
      }
      @include lg {
        font-size: f.$lstitle;
      }
      text-transform: uppercase;
      color: white;
    }
  }
}

///--------------------------------------------- cooperation -----------------------------------------

.cooperation {
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  flex-wrap: wrap;
  

  &__card {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2em;
    @include sm {
      height: 130px;
      width: 130px;
      font-size: f.$sstitle;
    }
    @include md {
      height: 170px;
      width: 170px;
      font-size: f.$mstitle;
    }
    @include lg {
      height: 250px;
      width: 250px;
      font-size: f.$lstitle;
    }

    &--circle {
      align-content: center;
      margin: 0 auto;
      margin-bottom: 1em;
      @include sm {
        height: 40px;
        width: 40px;
        font-size: f.$sstitle;
      }
      @include md {
        height: 50px;
        width: 50px;
        font-size: f.$mstitle;
      }
      @include lg {
        height: 60px;
        width: 60px;
        font-size: f.$lstitle;
      }
      background: c.$greendark;
      border: 1px;
      border-radius: 50%;
      
      .cooperation__card--image {
        @include sm {
          margin-top: 7px;
        }
        @include md {
          margin-top: 12px;
        }
        @include lg {
          margin-top: 17px;
        }
        filter: invert(90%);
      }
    }

    &--title {
      @include sm {
        font-size: f.$sstitle;
      }
      @include md {
        font-size: f.$mstitle;
      }
      @include lg {
        font-size: f.$lstitle;
      }
    }
    &--text {
      margin-top: 1em;
      @include sm {
        font-size: f.$sstext;
      }
      @include md {
        font-size: f.$mstext;
      }
      @include lg {
        font-size: f.$lstext;
      }
    }
  }
  &__card:hover {
    transform: scale(1.1);
  }
}

///--------------------------------------------- recomendation -----------------------------------------

  .recomendation {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    @include sm {
      background-image: url(/dist/img/shaking-hands_640.webp);
    }
    @include md {
      background-image: url(/dist/img/shaking-hands_1280.webp);
    }
    @include lg {
      background-image: url(/dist/img/shaking-hands_1900.webp);
    }
    background-position: center;
    background-size: cover;
    background-attachment:scroll;

    &__shadow{
      position: absolute;
      height: 100%;
      width: 100%;
      background-color:rgba(0, 0, 0, .6) ;
      z-index: 0;
    }
    
    &__head {
      position: relative;
      text-align: center;
      @include sm {
        font-size: f.$sshead;
      }
      @include md {
        font-size: f.$mshead;
      }
      @include lg {
        font-size: f.$lshead;
      }
      font-weight: 500;
      margin-top: 1em;
      padding: .4em 0em .4em 0em;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: c.$white;
      z-index: 2;
    }
    &__line {
      position: relative;
      color: c.$white;
      z-index: 3;
    }

    &__box {
      //position: relative;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin: 1em .5em;

      &--item {
        position: relative;
        margin: 1.5em .5em 2em .5em;
        @include sm {
          height: 150px;
          width: 200px;
        }
        @include md {
          height: 200px;
          width: 150px;
        }
        @include lg {
          height: 300px;
          width: 250px;
        }     
        &-img {
          position: relative;
          object-fit: cover;
          height: 100%;
          width: 100%;
          cursor: pointer;    
        }
        &-img:hover {
          transform: scale(1.02);
        }
      }   
    }
    &__modal {
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 10%;
      right: 10%;
      top: 10%;
      @include sm {
        width: 80%;
        height: 70%;
      }
      @include md {
        width: 50%;
        height: 50%;
      }
      @include lg {
        width: 28%;
        height: 90%;
      }
      margin: 0 auto;
      background-color: rgba(0, 0, 0, .9);
      z-index: 122222;
      visibility: hidden;
      transition: opacity 0.4s ease-in-out;
      cursor: pointer;
     }  
  }
  .modal__img {
    position: relative;
    height: 95%;
    width: 95%;
    //transform: scale(1);
    transition: 0.4s ease-in-out;
    object-fit:fill;
    }
  
  ///--------------------------------------------- about us -----------------------------------------
  

.aboutus {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @include sm {
    flex-direction: column;
  }
  @include md {
    flex-direction: row;
  }

  &__boxes {
    position: relative;
    display: flex;
    justify-content: center;
    align-items:stretch;
    flex-wrap: wrap;
    margin-top: 1em;
    margin-bottom: 1px;
    width: 100%;
    @include sm {
      justify-content: space-evenly;
    }
    @include md {
      order: 1;
      height: 100%;
      width: 40%;
      border-radius: 10px;
    }
    background-size: cover;
    background-position: center;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, .1);

    &__box {
      text-align: center;
      padding: 1em;
      @include sm {
        height: 80px;
        width: 100px;
      }
      @include md {
        height: 70px;
        width: 170px;
      }
      @include lg {
        height: 90px;
        width: 200px;
      }
      
      &--number {
        padding: 0.5em;
        @include sm {
          font-size: f.$sstitle;
        }
        @include md {
          font-size: f.$mstitle;
        }
        @include lg {
          font-size: f.$lstitle;
        }
        font-weight: bold;
        color: c.$greendark;
      }
      &--text {
        @include sm {
          font-size: f.$sstext;
        }
        @include md {
          font-size: f.$mstext;
        }
        @include lg {
          font-size: f.$lstext;
        }
      }
    }
  }
  &__text {
    position: relative;

    @include sm {
      margin: 1em 2em;
    }
    @include md {
      margin: 0em 1em 0em 2em;
    }
    text-align: left;

    &--p {
      padding: 1em 0em 0em 0em;
      @include sm {
        font-size: f.$sstext;
      }
      @include md {
        font-size: f.$mstext;
      }
      @include lg {
        font-size: f.$lstext;
      }
    }
  }
  &__text ol {
    margin-left: 1em;
    @include sm {
      font-size: f.$sstext;
    }
    @include md {
      font-size: f.$mstext;
    }
    @include lg {
      font-size: f.$lstext;
    }
  }
}

///--------------------------------------------- contact -----------------------------------------

.contact {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  &__box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include sm {
      margin: 1em;
    }
    @include md {
      margin: 3em;
    }

    &--logo {
      position: relative;
      @include sm {
        width:80px;
        height:30px;
      }
      @include md {
        width:100px;
        height:40px;
      }
      @include lg {
        width:150px;
        height:50px;
      }

    }
    &--title {
      padding: 1em;
      
      @include sm {
        font-size: f.$sstitle;
      }
      @include md {
        font-size: f.$mstitle;
      }
      @include lg {
        font-size: f.$lstitle;
      }
      color: c.$greendark
    }
    &--text {
      padding-bottom: 0.5em;
      @include sm {
        font-size: f.$sstext;
      }
      @include md {
        font-size: f.$mstext;
      }
      @include lg {
        font-size: f.$lstext;
      }
      
      .contact__box--icon {
        @include sm {
          height: f.$sstext;
        }
        @include md {
          height: f.$mstext;
        }
        @include lg {
          height: f.$lstext;
        }
      }
    }
    &--a{
      display: block;
      text-align: center;
      @include sm {
        font-size: f.$sstext;
      }
      @include md {
        font-size: f.$mstext;
      }
      @include lg {
        font-size: f.$lstext;
      }
      text-decoration: none;
      text-transform: uppercase;
      color: c.$black;
    }
  }
}

///--------------------------------------------- footer -----------------------------------------

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-top: 3em;
  text-align: center;
  background-color: rgba(0, 0, 0, .2);

  @include sm {
    height: 10%;
  }
  @include md {
    height: 15%;
  }
  @include lg {
    height: 20%;
  }
}




.navShow {
  transform: translateX(0%);
  //visibility: visible;
  //display: block;
  //opacity: 1;
}

.visible {
  visibility: visible;
  //display: block;
  //opacity: 1;
}